.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vh;
  }
  
  .circular-loading {
    animation: rotate 2s linear infinite;
    width: 90px;
    height: 90px;
  }
  
  .circular-loading-path {
    stroke: #3498db;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 1;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  
  @keyframes color {
    100%, 0% {
      stroke: #3498db;
    }
    40% {
      stroke: #f39c12;
    }
    66% {
      stroke: #e74c3c;
    }
    80%, 90% {
      stroke: #9b59b6;
    }
  }