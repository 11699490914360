
.legend-container {
    background-color: #fef4ff;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 10px 30px;
    width: 100%;
    /* height: 250px; */
    position: relative;
    /*margin-left: 50px;*/
}

.legends-title {
    font-weight: bold;
    font-size: 20px;
    top: 30px;
    left: 25px;
}

.legend-column {
    /* position: absolute; */
    /* top: 80px; */
    margin-top: 12px;
}

.legend-item {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    float: left;
    margin-right: 22px;
}

.legend-color {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 4px;
    border: 2px solid white;
    display: inline-block;
}

.no-chemicals {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    color: red;
    font-weight: bold;
    font-size: 24px;
    font-family: Arial, sans-serif; 
  }