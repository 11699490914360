.app-container{
    position: fixed;
    background-color: var(--mdb-gray-100);
    height: 100vh;
    width: 100%;
}

.work-area{
    height: calc(100vh - 50px);
    overflow: auto;
    padding: 20px;
    background-color: var(--mdb-white);
}
@media (max-width: 991.98px){
.work-area {
    margin-left: 0 !important;
    width: calc(100% - 0px) !important;
}
.tab-let {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left !important; 
    padding: 0px 10px;
    /* cursor: pointer; */
    margin-top: -11px;
    margin-left: -20px;
    width: calc(100% + 40px) !important;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 20%) !important;
    overflow: scroll;
}
}