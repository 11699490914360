@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;600;700;800;900;1000&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');



html {
  scroll-behavior: smooth !important;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  scroll-behavior: smooth;
  color: #20292f;
}
p {
  margin-top: 0;
  margin-bottom: 0.8rem;
  font-size: 16px;
  line-height: 1.65em;
}
.create p {
  margin-top: -9px;
  font-size: 14px;
}
.pad-50 {
  padding: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-30 {
  margin-bottom: 30px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pl-30 {
  padding-left: 30px;
}
.pt-20 {
  padding-top: 20px;
}
.header-wraper {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #2a2b2f;
  background: url(/public/images/cover-bg-ejdh.png);
  background-size: 100%;
  background-repeat: no-repeat;
  height: 699px;
  margin-top: 60px;
}
.h-top {
  border-top: 4px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 15px 0px;
}
.nav-link {
  display: block;
  font-size: 18px;
  font-weight: 400;
  color: rgb(255 255 255);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  font-family: "Poppins", sans-serif;
  line-height: 22px;
}
a.navbar-brand.public-brand {
  width: 250px !important;
  justify-content: center !important;
  padding: 5px 10px !important;
  margin-bottom: 7px !important;
}
a.navbar-brand:after {
  content: "";
  position: absolute;
  width: 200px;
  height: 83px;
  background: #fff;
  z-index: 0;
  top: -27px;
  left: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}
.navbar-brand img {
  position: relative;
  z-index: 1;
  text-align: center;
  padding-left: 17px;
  width: 85%;
}
.navbar-brand {
  position: relative;
}
.btn-cbo {
  display: block;
  text-decoration: none;
  background: #eeeeee;
  color: #3a3a3a;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ffffff;
  margin: 0px 5px;
}
.btn-cbo i {
  padding-left: 8px;
  line-height: 23px;
}
.btn-cbo:hover {
  display: block;
  text-decoration: none;
  background: #ffffff;
  color: #3a3a3a;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #eeeeee;
}
.btn-res {
  display: block;
  text-decoration: none;
  background: #ffca1d;
  color: #3a3a3a;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ffca1d;
  margin: 0px 5px;
}
.btn-res:hover {
  display: block;
  text-decoration: none;
  background: #ffca1d;
  color: #3a3a3a;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ffca1d;
}
.banner h1 {
  font-size: 42px;
  font-weight: 800;
  color: transparent;
  padding-bottom: 40px;
  line-height: 53px;
}
.banner {
  display: block;
  padding: 200px 20px 0px  90px;
  position: relative;
  z-index: 1;
  text-align: left;
}
.banner p.lead {
  color: #ffffff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 200;
}
.banner p.lead a {
  color: #ffca1d;
}
.banner img {
  margin: 0px;
  width: 440px;
}


input.form-control.input-lg {
  padding: 1.46rem;
}

.carousel-indicators button {
  background-color: #fff;
  opacity: 0.5;
  margin: 0 2px;
  padding: 7px;
  transition: all 0.3s ease 0s;
}
.carousel-indicators button.active {
  padding: 7px 20px;
  transition: all 0.3s ease 0s;
}



@media (min-width: 992px) and (max-width: 1199.98px){
  .modal-lg,
  .modal-xl {
    --mdb-modal-width: 896px;
  }
  .banner {
    display: block;
    padding: 120px 20px 0px 40px;
    position: relative;
    z-index: 1;
    text-align: left;
}
.banner img {
  margin: 0px;
  width: 370px;
}
.header-wraper {
  height: 504px;
}
}
/*-------modal-bg ------------*/
.modal-bg {
  background: url(https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/modal-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid #ffffff;
}
.login-logo {
  position: absolute;
    top: 0px;
    left: 140px;
    width: 20%;
    background: #fff;
    padding: 0px 5px 5px 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.login-content {
  text-align: center;
  padding-top: 20px;
}
.login-content h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 800;
}
.login-content p {
  color: #fff;
  padding-left: 8%;
  padding-right: 8%;
  line-height: 21px;
}
.carousel-caption {
  position: initial;
}
.carousel-caption{
  padding-top: 0px;
  padding-bottom: 0px;
}
.w-65 {
  width: 65% !important;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 35px;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 0;
  margin-left: 15%;
}
.modal {
  background-color: rgb(0 0 0 / 43%);
}
.pt-30 {
  padding-top: 30px;
}
.flot-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  color: #5c5c5c;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 37px;
  z-index: 2;
  height: 100%;
  padding: 10px 0px;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 20px;
  padding-bottom: 0.625rem;
  padding-left: 40px;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: 44px;
  min-height: 44px;
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  display: none !important;
}
.btn-primary {
  background: linear-gradient(144.77deg, #138ee7 -42.01%, #18499c 156.86%);
}
.form-btn {
  padding: 6px 35px;
  min-width: 50%  !important;
  max-width: 100%  !important;
  width: auto  !important;
  border-radius: 20px !important;
  font-size: 15px;
  /* margin: 0px 25%; */
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 1.125rem;
  text-align: left;
}
.form-box {
  background: #fff;
  padding: 20px 20px;
  border-radius: 10px;
  margin-top: -50px;
  min-height: 330px;
}
.inner-form-box {
  border: 2px solid #2060a4;
  border-radius: 10px;
  padding: 22% 20px 18% 20px;
  display: block;
  text-align: center;
  position: relative;
  min-height: 440px !important;
}
.inner-form-box2 {
  border: 2px solid #2060a4;
  border-radius: 10px;
  padding: 30px 20px;
  display: block;
  text-align: center;
  position: relative;
}
.form-icon {
  font-size: 30px;
  border: 2px solid #2060a4;
  padding: 15px 10px;
  border-radius: 40px;
  width: 60px;
  height: 60px;
  color: #2060a4;
  margin-bottom: 18px;
}
h3.form-title {
  position: absolute;
  font-size: 20px;
  font-weight: 800;
  color: #2060a4;
  background: #fff;
  top: -12px;
  left: 36%;
  padding-left: 6px;
  padding-right: 6px;
}
h3.form-title2 {
  position: absolute;
  font-size: 20px;
  font-weight: 800;
  color: #2060a4;
  background: #fff;
  top: -12px;
  left: 28%;
  padding-left: 6px;
  padding-right: 6px;
}
.btn-close {
  color: #fff;
  background: none;
  font-size: 20px;
  opacity: 1;
  margin-right: -18px !important;
}
.modal-header {
  border-bottom: 0px;
}
.form-footer-title {
  font-size: 14px;
  position: absolute;
  background: #fff;
  bottom: -30px;
  left: 15.5%;
  padding-left: 6px;
  padding-right: 6px;
}
.form-footer-title a {
  font-weight: bold;
  text-decoration: none;
  margin-left: 6px;
  color: #232323;
}

/* Hide all steps by default: */
.tab {
  display: none;
}

button {
  background-color: #1a5d9f;
  color: #ffffff;
  border: none;
  padding: 7px 34px;
  font-size: 17px;
  cursor: pointer;
  border-radius: 20px;
}

button[type="submit"]:focus {
  outline: solid 1px #68b3ff;
  outline-offset: -4px;
}

#ResendBtn {
  background-color: #dbdbdb;
  color: #5c5c5c;
}

button:hover {
  opacity: 0.8;
}

#prevBtn {
  background-color: #bbbbbb;
}

/* Make circles that indicate the steps of the form: */
.steps-con .step {
  height: 15px !important;
  width: 15px !important;
  margin: 0 2px !important;
  background-color: #bbbbbb !important;
  border: none !important;
  border-radius: 50% !important;
  display: inline-block !important;
  opacity: 0.5 !important;
}

.steps-con .step.active {
  opacity: 1 !important;
}
.main-sec {
  padding: 60px 0px 60px;
  z-index: 1;
  position: relative;
}
.abt-baner {
  padding: 7% 4%;
}
.abt-info {
  position: relative;
  display: block;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 60px;
}
.abt-info2 {
  position: relative;
  display: block;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
}
.section-title__tagline {
  font-size: 18px;
  color: rgb(133 133 133);
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.abt-title {
  font-size: 34px;
  font-weight: 800;
  padding-top: 9px;
  padding-bottom: 10px;
}
.btn-alink {
  text-decoration: none;
  background: rgb(25 154 214);
  color: #ffffff;
  border-radius: 25px;
  border: 1px solid #ffffff;
  padding: 5px 18px;
  margin-top: 12px;
  display: inline-block;
}
a.btn-alink:hover {
  background: #fff;
  border: 1px solid rgb(25 154 214);
  color: rgb(25 154 214);
  box-shadow: 0 0 5px #d9d9d9;
}
.shadow-lg {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 7%) !important;
}
.card2 {
  max-width: calc(100% - 0px) !important;
  height: 360px;
}
.card2 img{
  width:75px;
}
.card-hover {
  border: 1px solid #a3a3a3;
}

.card-body h4 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 6px;
  color: #2b2c2d;
}
.card-body p {
  font-size: 16px;
  line-height: 24px;
}
.card-body a {
  position: absolute;
  bottom: -21px;
  left: 43%;
  display: none;
}
a.more i {
  font-size: 23px;
  color: #257dc1;
  background: #ffffff;
  padding: 10px 15px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px #ddd;
}
/* .card-hover:hover {
  background: #257dc1;
} */
/* .card-hover:hover .card-body h4,
.card-hover:hover .card-body p {
  color: #fff;
} */
.card-hover:hover .card-body a {
  display: block;
}

.bg-blue {
  background: linear-gradient(135deg, #1e74af 0%, #2a5298 100%);
}
.c-white {
  color: #fff;
}
.steps-con .step-cord span {
  display: inline-block !important;
  background: #fff !important;
  padding: 14px 22px !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-bottom: 22px !important;
  border-radius: 25px !important;
  color: #2566a5 !important;
}
.step-cord img {
  padding-left: 40px !important ;
  margin-top: -10px !important ;
  width: 70% !important ;
}
.step-cord h4 {
  color: #fff !important;
    font-weight: 700 !important;
    font-size: 18px;
    padding-bottom: 0px !important;
}
.steps-con:before {
  content: "" !important ;
  position: absolute !important ;
  width: 35% !important ;
  height: 615px !important ;
  background: url(https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/overlay-bg1.png);
  z-index: -1 !important ;
  background-repeat: no-repeat !important ;
  top: 15px !important ;
}
.steps-con:after {
  content: "" !important ;
  position: absolute !important ;
  width: 35% !important ;
  height: 615px !important ;
  background: url(https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/overlay-bg2.png);
  z-index: -1 !important ;
  background-repeat: no-repeat !important ;
  bottom: -134px !important ;
  right: 0px !important ;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
.accordion-button:not(.collapsed) {
  color: #267dc1;
  background-color: transparent;
  box-shadow: none;
}
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: 0;
}
.accordion-header {
  margin-bottom: 0;
  border-bottom: 1px solid rgba(229, 236, 244, 1);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: url(https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/collapsed.png);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  position: absolute;
}
.accordion-button:not(.collapsed)::after {
  background-image: url(https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/expand.png);
  transform: var(--bs-accordion-btn-icon-transform);
  position: absolute;
}

.accordion-body {
  font-size: 14px;
}
button.accordion-button span {
  padding-left: 35px;
}
.dots-bg {
  background-image: url(https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/subscribe-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  padding-top: 62px;
  padding-bottom: 62px;
  border-radius: 40px;
}
span.cylw {
  color: #ffca1d;
  text-align: center;
  font-size: 24px;
  font-family: Nunito Sans;
  font-weight: 700;
  letter-spacing: 0.48px;
}
.sub-txt h3 {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-family: Nunito Sans;
  font-weight: 700;
  letter-spacing: 0.6px;
}
.sub-txt p {
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-family: Nunito Sans;
  font-weight: 300;
  letter-spacing: 0.44px;
}
.form-subscribe {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 25px;
}
.form-subscribe .form-control {
  background-color: #fff;
  padding-left: 24px;
  padding-right: 24px;
  letter-spacing: 1px;
  border: none;
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
}
.form-subscribe .form-control.focus,
.form-subscribe .form-control:focus {
  z-index: 2;
  background-color: hsla(0, 0%, 100%, 0.8);
}
.form-subscribe .btn {
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
  background: #7ec855;
  border-color: #7ec855;
  height: 46.5px;
}
.form-subscribe .btn {
  border-radius: 0px;
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
  background: #4191d3;
  height: 46.5px;
  border: 2px solid #fff;
}

.footer {
  position: relative;
  background: rgba(242, 248, 255, 1);
  padding-top: 80px;
}
/* 
.single_footer {
} */
@media only screen and (max-width: 768px) {
  .single_footer {
    margin-bottom: 30px;
  }
  a.navbar-brand.public-brand {
    width: 99px !important;
    justify-content: center !important;
    padding: 0 !important;
    margin-bottom: 0px !important;
}
}
.single_footer h4 {
  color: #333;
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
}
.single_footer h4::after {
  content: "";
  display: block;
  height: 2px;
  width: 40px;
  background: #333;
  margin-top: 20px;
}
.single_footer p {
  color: #fff;
}
.single_footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
/* .single_footer ul li {
} */
.single_footer ul li a {
  color: #333;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 36px;
  font-size: 15px;
  text-transform: capitalize;
  text-decoration: none;
}
.single_footer ul li a:hover {
  color: #333333;
  font-weight: 700;
}

/* .single_footer_address {
}
.single_footer_address ul {
} */
.single_footer_address ul li {
  color: #fff;
}
.single_footer_address ul li span {
  font-weight: 400;
  color: #fff;
  line-height: 28px;
}
.contact_social ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.subscribe {
  display: block;
  position: relative;
  margin-top: 15px;
  width: 100%;
}
.subscribe__input {
  background-color: #fff;
  border: medium none;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 60px;
  letter-spacing: 0.4px;
  margin: 0;
  padding: 0 150px 0 20px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .subscribe__input {
    padding: 0 50px 0 20px;
  }
}

.subscribe__btn {
  background-color: transparent;
  border-radius: 0 25px 25px 0;
  color: #01c7e9;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}
.subscribe__btn i {
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 768px) {
  .subscribe__btn {
    right: 0px;
  }
}

.subscribe__btn:hover i {
  color: #ff3666;
}

/*START SOCIAL PROFILE CSS*/
.social_profile {
  margin-top: 40px;
}
.social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.single_footer ul li a.fb {
  background: #3b5998;
  padding: 5px 12px;
  color: #fff;
  border-radius: 20px;
  padding-left: 4px;
}
a.fb i {
  background: #fff;
  padding: 4px 8px;
  color: #3c5997;
  border-radius: 20px;
  margin-right: 6px;
}
.single_footer ul li a.twtr {
  background: #00acee;
  padding: 5px 12px;
  color: #fff;
  border-radius: 20px;
  padding-left: 4px;
  padding-right: 27px;
}
a.twtr i {
  background: #fff;
  padding: 6px 6px;
  color: #3c5997;
  border-radius: 20px;
  margin-right: 6px;
  line-height: 13px;
}
.single_footer ul li a.lkdn {
  background: #0a66c2;
  padding: 5px 12px;
  color: #fff;
  border-radius: 20px;
  padding-left: 5px;
  padding-right: 18px;
}
a.lkdn i {
  background: #fff;
  padding: 6px 6px;
  color: #3c5997;
  border-radius: 20px;
  margin-right: 6px;
}
.social_profile ul li {
  float: left;
}
.social_profile ul li a {
  text-align: center;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  margin: 0px 5px;
  font-size: 18px;
  color: #fff;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
@media only screen and (max-width: 768px) {
  .social_profile ul li a {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .social_profile ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
.social_profile ul li a:hover {
  background: #ff3666;
  border: 1px solid #ff3666;
  color: #fff;
  border: 0px;
}
/*END SOCIAL PROFILE CSS*/
.copyright {
  color: #fff;
  font-size: 15px;
  text-align: center;
}
.bottom-footer {
  background: rgb(25 154 214);
    padding-top: 25px;
    padding-bottom: 10px;
    margin-top: 80px;
    z-index: 10;
    position: relative;
}
.copyright a {
  color: #ffffff;
  transition: all 0.2s ease 0s;
}
.copyright a:hover {
  color: #b7ffbc;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1270px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .header-wraper {
    position: relative;
    background: url(/public/images/cover-bg-ejdh.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
      height: 504px;
  }
  a.navbar-brand.public-brand{
    height: auto;
  }
  .navbar-brand img {
    padding-left: 0 !important;
}
.navbar-brand img {
  top: 0px;
}
  .banner {
    display: block;
    padding: 120px 20px;
    position: relative;
    z-index: 1;
  }

  .banner h1 {
    font-size: 40px;
    font-weight: 800;
    color: #ffffff;
    padding-bottom: 10px;
    line-height: 50px;
  }
  .banner img {
    width: 55%;
  }
  .main-sec {
    padding: 50px 0px;
  }
  .abt-baner {
    padding: 0%;
  }
  .abt-info {
    position: relative;
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;
  }
  .section-title__tagline {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .abt-title {
    font-size: 24px;
    font-weight: 800;
    padding-top: 9px;
    padding-bottom: 8px;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 16px;
    line-height: 26px;
  }
  .btn-alink {
    text-decoration: none;
    background: rgba(37, 125, 193, 1);
    color: #ffffff;
    border-radius: 25px;
    border: 1px solid #ffffff;
    padding: 6px 12px;
    margin-top: 0px;
    display: inline-block;
  }
  .steps-con:before {
    content: "";
    position: absolute;
    width: 60%;
    height: 512px;
    background: url(https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/overlay-bg1.png);
    z-index: -1;
    background-repeat: no-repeat;
    top: 15px;
    background-size: contain;
  }
  .steps-con:after {
    content: "";
    position: absolute;
    width: 60%;
    height: 512px;
    background: url(https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/overlay-bg2.png);
    z-index: -1;
    background-repeat: no-repeat;
    bottom: -81px;
    right: 0px;
    background-size: contain;
  }
}

@media (max-width: 768px) {
  .hide-xsm {
    display: none;
  }
  a.navbar-brand.public-brand { 
    height: auto;
    box-shadow: none;
}
.navbar-brand img {
    padding-left: 0 !important;
    width: 100% !important;
    top: 0px !important;
}
.navbar-brand {
  background: transparent;
  z-index: 0;
  top: 0;
  left: 0;
}
.header .navbar.navbar-expand-lg .container {
  height: auto !important;
} 
.header-wraper {
  height: 360px;
}
.banner img {
  margin: 0px;
  width: 50%;
}
.banner {
  padding: 55px 20px 0px 0px;
}
.btn-blue, .btn-red{
  font-size: 12px;
}
.banner p.lead {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 200;
}
}

@media (max-width: 575.98px) {
  .h-top {
    border-top: 4px solid #ffffff;
    border-bottom: 1px solid #fff;
    background: #fff;
    padding: 0px;
  }
  .navbar-brand img {
    position: relative;
    z-index: 1;
    text-align: center;
    padding-left: 10px;
  }
  a.navbar-brand:after {
    display: none;
  }

  .header-wraper {
    background: url(/public/images/cover-bg-ejdh.png);
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .banner {
    display: block;
    padding: 40px 20px;
    position: relative;
    z-index: 1;
    top: 40px;
  }

  .banner h1 {
    font-size: 26px;
    font-weight: 800;
    color: #ffffff;
    padding-bottom: 4px;
    line-height: 33px;
  }
  .banner p.lead {
    color: #ffffff;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  .banner img {
    /* position: absolute;
    top: 25px;
    left: 13px; */
    z-index: -1;
    width: 55%;
  }
  .header-wraper {
    background: url(/public/images/cover-bg-ejdh.png);
    /* background-attachment: fixed; */
    background-position: 46%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 320px;
  }
  .abt-baner {
    padding: 10px;
  }
  .main-sec {
    padding: 40px 0px;
  }
  .abt-info {
    position: relative;
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 40px;
    /* text-align: center; */
  }
  .section-title__tagline {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .abt-title {
    font-size: 22px;
    font-weight: 800;
    padding-top: 9px;
    padding-bottom: 6px;
    line-height: 28px;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 14px;
    line-height: 23px;
  }
  .btn-alink {
    text-decoration: none;
    background: rgba(37, 125, 193, 1);
    color: #ffffff;
    border-radius: 25px;
    border: 1px solid #ffffff;
    padding: 3px 14px;
    margin-top: 0;
    display: inline-block;
  }
  .steps-con:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 260px;
    background: url(https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/overlay-bg1.png);
    z-index: -1;
    background-repeat: no-repeat;
    top: 15px;
    background-size: contain;
  }
  .steps-con:after {
    content: "";
    position: absolute;
    width: 50%;
    height: 260px;
    background: url(https://ejdhsandbox01.blob.core.windows.net/b2cauth/assets/images/overlay-bg2.png);
    z-index: -1;
    background-repeat: no-repeat;
    bottom: -76px;
    right: 0px;
    background-size: contain;
  }
  .form-box {
    background: #fff;
    padding: 20px 20px;
    border-radius: 10px;
    margin-top: -50px;
    height: auto;
  }
  .form-footer-title {
    font-size: 12px;
    position: absolute;
    background: #fff;
    bottom: -27px;
    left: 10.5%;
    padding-left: 6px;
    padding-right: 6px;
  }
}

/*  Azuure overlaps */
#api div.heading,
#api form div.intro,
#api div.intro {
  display: none;
}

.error.itemLevel,
.error.itemLevel p {
  display: inline-block;
  position: absolute;
  top: 40px;
  width: 100%;
  left: 0;
  text-align: left;
  padding-left: 5px;
  font-size: 12px;
  color: #cc0000;
  padding-top: 1px;
}

.error.pageLevel,
#emailVerificationControl_error_message {
  color: #cc0000;
}

.working {
  position: relative;
  height: 0;
  top: 9px;
  left: -33px;
  color: #ffffff;
}

#forgotPassword {
  text-decoration: none;
  color: #555555;
}

.step-cord {
  overflow-x: visible !important;
}
.step-content {
  width: 9rem;
  max-width: 9rem;
}
.step-success .step-circle {
  color: #fff;
  background-color: #2060a4 !important;
  border-color: #2060a4 !important;
}
.step-success .step-text {
  color: #2060a4 !important;
}
.step-active .step-circle {
  color: #2060a4;
  background-color: #fff !important;
  border-color: #2060a4 !important;
}
.step-active .step-text {
  color: #2060a4 !important;
}
.step-circle::before {
  left: -1px;
  width: calc(9rem + 1rem - 1.5rem);
}
.step-active .step-circle::before {
  color: #2060a4;
}

#attributeVerification .working {
  color: #2060a4;
  left: 0px !important;
  top: -13px !important;
  margin: 10px !important;
}
.flip-container{
  transform-style: preserve-3d;
}
.flip-box{
  position: relative;
    transform-style: preserve-3d;
    perspective: 2500px;
    height: 360px;
}
.flip-box-body{
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  transition: 0.9s ease;
}
.flip-box .content{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2b2c2d;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  border-radius: 5px;
}
.flip-box:hover .flip-box-body{
  transform: rotateY(180deg);
}
.flip-box:hover .card-hover {
  border: 0px;
}
.flip-box .content div {
  transform-style: preserve-3d;
  padding: 20px;
  background: #f05424;
  transform: translateZ(100px);
}
.flip-box .content div p{
  font-size: 14px;
  line-height: 22px;
}
.faq-card {
  position: relative;
  display: flex;
  padding: 6.1rem 9.4rem 8.4rem 0;
  margin: 0;
  background-image: url(https://raw.githubusercontent.com/MizAndhre/FAQ-accordion-card/2ff2a02…/images/bg-pattern-desktop.svg);
  background-size: 100%;
  background-position: -73rem center;
  background-position: -53rem -28rem;
  background-color: #fff;
    border-radius: 2.5rem;
    min-width: 33rem;
    max-width: 92rem;
}



@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 890px;
  }
}


.wave {
  position: absolute;
  BOTTOM: 0px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(/public/images/wave.png);
  background-size: 1000px 100px;
}

.wave#wave1 {
  z-index: 8;
  opacity: 1;
  bottom: 48px;
  animation: animateWaves 10s linear infinite;
}
.wave#wave2 {
  z-index: 9;
  opacity: 0.5;
  bottom: 25px;
  animation: animate 10s linear infinite !important;
}
.wave#wave3 {
  z-index: 8;
  opacity: 0.2;
  bottom: 35px;
  animation: animateWaves 6s linear infinite;
}

.wave#wave4 {
  z-index: 9;
  opacity: 0.7;
  bottom: 50px;
  animation: animate 6s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}