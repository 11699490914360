.ag-root-wrapper{
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}
.ag-header,
.ag-header-cell{
    background-color: rgba(var(--mdb-primary-rgb), var(--mdb-bg-opacity)) !important;
    color: var(--mdb-white) !important;
}
.ag-icon{
    background-color: rgba(var(--mdb-primary-rgb), var(--mdb-bg-opacity)) !important;
    color: var(--mdb-white) !important;
}

.datagrid-view .f-column.datagrid-view1{
    width: 400px !important;
    border-bottom: solid 17px #dbdbdb;
}
.datagrid-view .f-column.datagrid-view1 .datagrid-cell{
    white-space: normal !important;
    word-wrap: break-word !important;
}
.datagrid-row {
    height: 40px !important;
}
.datagrid-header .datagrid-header-row,
.datagrid-filter-c {
    height: 30px !important;
}

.datagrid-header,
.datagrid-header,
.ant-table-header,
.ant-table-header table,
.ant-table-header table thead tr
.ant-table-header table thead tr th,
.ant-table-wrapper .ant-table-thead th.ant-table-column-sort,
.ant-table-wrapper .ant-table-thead >tr>th{
    background-color: rgba(var(--mdb-primary-rgb), 0.4) !important;
    background: rgba(var(--mdb-primary-rgb), 0.4) !important;
    border-color: rgba(var(--mdb-primary-rgb), 0.4) !important;
}
.datagrid-header td,
.ant-table-wrapper .ant-table-thead th.ant-table-column-sort,
.ant-table-wrapper .ant-table-thead >tr>th,
.ant-table-wrapper .ant-table-column-sorter-down.active,
.ant-table-wrapper .ant-table-column-sorter-up.active{
    background-color: rgb(25 138 196) !important;
    color: var(--mdb-white) !important;
}
.datagrid-header td input{
    background-color: rgba(var(--mdb-primary-rgb), 0.7) !important;
    color: var(--mdb-white) !important;
    padding: 3px 4px !important;
    height: 22px !important;
}
.datagrid-header td input::placeholder{
    color: rgba(var(--mdb-white), 0.4) !important;
}

.datagrid-filter-c> .f-field, .datagrid-filter-c> *{
    border-radius: 7px !important;;
    height: 22px !important;
}

.datagrid-htable{
    height: 60px !important;
}
.datagrid-header-row .datagrid-cell{
    text-align: center !important;
}

/* .ant-table-wrapper .ant-table .ant-table-header{
    border-radius: 0 !important;
} */

.ant-table-wrapper .ant-table-row-indent+.ant-table-row-expand-icon{
    zoom: 1.5 !important;
    background: rgb(var(--mdb-primary-rgb)) !important;
    border: solid 1px rgb(var(--mdb-white)) !important;
    color: var(--mdb-white) !important;
    border-radius: 50% !important;
    transition: all 0.3s ease-in-out !important;
}
:where(.css-dev-only-do-not-override-1okl62o).ant-table-wrapper .ant-table-row-expand-icon-spaced {
    margin-bottom: 25px !important;
}
:where(.css-1xg9z9n).ant-table-wrapper .ant-table-row-expand-icon-spaced {
    margin-bottom: 25px !important;
}
:where(.css-apn68).ant-table-wrapper .ant-table-row-expand-icon-spaced{
    margin-bottom: 25px !important;
}
.ant-table-wrapper .ant-table-row-indent+.ant-table-row-expand-icon:hover{
    box-shadow: 0 0 2px 1px #adadad !important;
    transition: all 0.3s ease-in-out !important;
}

.ant-table-wrapper .ant-table-row-expand-icon::after{
    inset-inline-start: 8px !important; 
}
.ant-table-wrapper .ant-table-row-expand-icon::before{
    top: 8px !important;
}
.ghg-grid .ant-table-row.ant-table-row-level-0:last-child,
.tri-grid .ant-table-row.ant-table-row-level-0:last-child{
    font-weight: bold !important;
    font-size: 14px !important;
    text-align: center !important;
}
