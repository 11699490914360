.password-form {
    padding: 8%;
    background: #f4a71e;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}
.form-valid {
    padding: 8% 5% !important;
   
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}
.w-80{
    width:60% !important;
}
.pwd-icon{
    font-size: 40px;
    margin-bottom: 10px;
    color: #f4a71e;
    padding: 13px;
    border-radius: 50px;
    background: #fff;
    box-shadow: 0 0 15px #dc7a1e;
}
#togglePassword{
    position: absolute;
    top: 10px;
    right: 13px;
    color: #797979;
    cursor: pointer;
}