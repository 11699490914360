.dropdown-container {
  position: relative;
}

.chemical-dropdown {
  display: inline-block;
  position: relative;
  padding: 7px 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  margin: 3px;
  font-weight: bold;
  font-size: 14px;
  background-color: white;
  color: black;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-icon {
  margin-left: 50px;
  font-size: 14px;
  color: #198ac4;
}

.chemical-dropdown-text {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0px;
}

.chemical-dropdown:hover, .chemical-dropdown.open {
  background-color: #198ac4;
  color: white;
}

.chemical-dropdown:hover .dropdown-icon {
  color: white;
}

select {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid black;
  border-top: none;
  border-radius: 0 0 8px 8px;
  background-color: white;
  display: none;
}

.dropdown:hover select {
  display: block;
}

.dropdown-options-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.dropdown-options {
  height: 180px; 
  overflow-y: auto;
  margin-top: 8px;
  margin-bottom: 3px;
  position: relative;
  padding: 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.option {
  cursor: pointer;
  padding: 8px 10px;
}

.option:hover {
  background-color: #198ac4;
  color: #fff;
}