/* @import '~rc-easyui/dist/themes/default/easyui.css';
@import '~rc-easyui/dist/themes/icon.css';
@import '~rc-easyui/dist/themes/react.css'; */
html {
  scroll-behavior: smooth;
}
body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
.btn{
  box-shadow: none !important;
}

.btn .fas{
  margin-right: 0.5rem;
}

.hover-hand:hover{
  cursor: pointer !important;
}

.ml-2rem{
  margin-left: 1.6rem !important;
}
.fix-width{
  max-width: calc(100% - 40px);
}

.highcharts-credits{
  display: none !important;
}

.introjs-tooltip-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2px;
  padding-left: 4px;
  padding-top: 5px;
}

.introjs-skipbutton {
  padding: 0;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #7b8794;
}

.introjs-skipbutton:hover {
  color: black;
}

.introjs-tooltiptext {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: #4b5563;
}

.introjs-tooltipbuttons {
  border: none;
}

.introjs-button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  color: #2d3748;
  background-color: #ffffff;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: #d2d6dc;

}

.introjs-button:hover {
  background-color: #f3f4f6;
  border-color: #d2d6dc;

}

.introjs-button:focus {
  background-color: #ffffff;
  box-shadow: 0 0 0 0.125rem #0088cc;
  outline-offset: 0.125rem;
  border-color: #0088cc;
}
