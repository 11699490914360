
.notification-link {
    color: #fff;
    border: 2px solid #fff;
    padding: 3px 0 0 7px !important;
    border-radius: 50%;
    /* margin-top: 7px; */
    transition: all 0.3s linear;
    width: 30px;
    height: 30px;
}

.notification-link:hover,
.notification-link:focus-within{
    box-shadow: 0 4px 5px #363636b3;
}

.navbar-nav .notification-tray {
    top: -6px !important;
    left: -150px !important;
}

.tray{
    width: 200px;
}

.msg-card{
    border: 2px solid #E5E5E5;
    border-radius: 10px;
    overflow: hidden;
}
.tray-body{
    max-height: 300px;
    overflow-y: auto;
}
.msg-card:hover{
    border-radius: 10px !important;
}

.msg-card .text-muted{
    color: #7a7a7a !important;
    overflow: hidden;
}
.tray-body .dropdown-item{
    width: 89%;
}
.tray-footer .dropdown-item{
    width: 100%;
}

@media (max-width: 992px) {
    .navbar-nav .notification-tray {
        transform: translate3d(-232.333px, 46px, 0px) !important;
    }

}