a {
    color: #199ad6;
    text-decoration: none;
}
a:hover {
    color: #f05424;
    text-decoration: none;
}
.app-container{
    font-family: "Open Sans", sans-serif;
    background-color: var(--bg-color);
}

.loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh !important;
    margin-top: calc(50vh - 150px);
}
.loading svg {
    margin: 0 !important;
}

.form-container{
    padding: 2.5rem;
    width: 100%;
    box-shadow: 0 3px 10px 1px rgb(0 0 0 / 17%) !important;
    border-radius: 10px;
}

.card{
    box-shadow: 0px 0px 5px 3px rgb(0 0 0 / 35%) !important;
}

.sidebar {
    box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.155) !important;
}

/* .navbar{
    box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 23%) !important;
} */