.nav-user {
    display: flex;
    gap: 10px;
    border-width: 1px;
    border-style: solid;
    transition: none;
    border-radius: 29px;
    border: 0;
    background: #d1eeff;
    flex-direction: row;
    transition: all 0.3s linear;
    padding: 2px;
}
.fa-2x {
    font-size: 1.7em;
    line-height: 33px !important;
}

.drop-handle{
    font-size: 1rem !important;
    margin-top: 8px;
    margin-right: 8px;
}

.user-info .fa{
    font-size: 1.5rem !important;
    margin-top: 2px;
    margin-left: 2px
}

.nav-user:hover,
.nav-user:focus-within{
    box-shadow: 0 4px 5px #363636b3;
}

.nav-user .user-info-res,
.nav-user .user-info{
    min-width: 100px;
    padding-top: 2px;
    font-weight: bold;
    position: relative;
    top: 4px;
    max-width: 140px;
    overflow-x: hidden;
}

.nav-user .user-info-res{
    min-width: 70px !important;
    padding-top: 8px !important;
}

.user-info-res {
    margin: 0px 45px 0 18px;
}
a.text-res{
    color: #c3e8fb;
    font-size: 12px;
}
a.text-res i{
    padding-right: 6px;
}
a.text-res:hover {
    color: #fdc525;
    text-decoration: underline;
}


/* .user-icon{
    color: #4c4c4c !important;
} */
.nav-user .user-info-res .user-name,
.nav-user .user-info .user-name{
    font-size: .8rem;
    line-height: 8px;
    color: #050606;
    font-weight: 500;
    max-width: 200px;
    text-transform: capitalize;
}
.nav-user .user-info-res .user-job,
.nav-user .user-info .user-job{
    font-size: .7rem;
    color: #4c4c4c;
    font-weight: 400 !important;
    padding-top: 1px;
    font-weight: bold;
}

.user-dd-menu{
    top: -8px !important;
    left: 18px !important;
}

/* .dropdown-menu > li > a {
    padding: 0 !important;
} */
.dropdown-menu > li > div,
.dropdown-menu > li > div >a {
    width: 100% !important;

}
.user-dd-menu:before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
}
.dropdown-menu > li > div >a {
    display: block !important;
    padding: 10px !important;
    width: 100% !important;
}
.dropdown-menu > li:first-child > div {
    border-radius: 5px 5px 0 0;
}
.border-2 {
    --mdb-border-width: 1px !important;
}
.dropdown-menu > liL:last-child > div {
    border-radius: 0 0 5px 5px;
}
/* .dropdown-menu > li > a {
    padding: 0 !important;
} */
.dropdown-menu > a > div{
    padding: 10px !important;
    width: 100%;
    display: block;
}
.user-tray > a {
    width: 100%;
    display: block;
}
.user-tray .dropdown-item{
    padding: 0px !important;
}

@media (max-width: 992px) {
    .drop-handle,
    .nav-user .user-info{
        display: none;
    }

    .navbar-nav .dropdown-menu.user-tray{
        transform: translate3d(-142.33333299999998px, 49.6667px, 0px) !important;
    }

}
