
.app-container{
    background-color: var(--mdb-white) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    scroll-behavior: smooth;
}

.navbar{
    box-shadow: none !important;
}
.navbar-brand{
    position: relative;
    width: 200px;
    height: 126px;
    background: #fff;
    z-index: 0;
    top: -27px;
    left: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    justify-content: center;
}
a.navbar-brand.public-brand {
    width: auto !important;
    justify-content: center !important;
    padding: 5px 15px !important;
    margin-bottom: 7px !important;
    height: 120px;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
  }
a.navbar-brand:after {
    height: 153px !important;
}
.navbar-brand:after,
.public-brand:after {
    content: "";
    position: absolute;
    width: 200px;
    height: 83px;
    background: #fff;
    z-index: 0;
    top: -27px;
    left: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}
.navbar-brand img {
    top: 21px;
    padding-left: 0 !important;
    left: 0px;
}
.nav-link:hover, .nav-link:focus {
    color: rgb(255 202 38);
}

.dropdown-menu li {
    position: relative;
    }
    .dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
    }
    .dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
    }
    .dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
    }
    a.dropdown-item img {
        width: 20px;
        margin-right: 8px;
    }

/* .h-top {
    padding: 0px !important;
} */
.header-two {
    background: #199ad6 !important;
}
.header .navbar.navbar-expand-lg .container{
    height: 54px !important;
}

button#btn_cbo, button#btn_res {
    font-size: 14px;
    font-weight: normal;
    padding: 5px 20px;
    text-transform: none;
    line-height: 23px;
}
.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; 
    background: #2b2c2d;
    z-index: 999;
    transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}
.btn {
    padding: 4px 15px !important;
  }
.btn-blue {
    background: #199ad6 !important;
    font-size: 16px;
    font-weight: 500;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
.btn-red {
    background: #f05424 !important;
    font-size: 16px;
    font-weight: 500;
    padding-left: 25px !important;
    padding-right: 25px !important;
}
/* Media query for mobile screens */
@media (max-width: 991.98px) {
    .navbar-nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #199ad6; /* Background color of collapsed navbar */
        width: 100%;
    }

    .navbar-collapse {
        position: absolute;
        top: 55px; /* Adjust to your navbar height */
        left: 0;
        right: 0;
        z-index: 9999;
        background-color: #f8f9fa; /* Ensure background is consistent */
        padding: 7px;
        width: 100%;
        height: 142px !important;
    }

    .fixed-header .navbar-toggler {
        border: none;
        top: -23px;
        position: relative;
        color: #fff;
    }


    /* Customize nav-links */
    .navbar-nav .nav-link {
        padding: 10px;
        text-align: center;
        font-size: 1rem;
        width: 100%;
    }

   
}

@media (max-width: 768px) {
    .header-two {
        background: #f3fbff !important;
    }
    .fixed-header .navbar-toggler {
        border: none;
        top: 0px;
        position: relative;
        color: #fff;
    }
}

@media (max-width: 575.98px) {
    .fixed-header .navbar-toggler {
        color:  var(--mdb-navbar-color);
    }
}