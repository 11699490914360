.tile {
    box-shadow: 0 3px 10px 1px rgb(0 0 0 / 17%) !important;
    border-radius: 8px;
    width: 370px;
    height: 320px;
    position: relative;
    background: #189bd6;
    /* "to left" / "to right" - affects initial color */
    background: linear-gradient(to left, rgb(255, 255, 255) 50%, #189bd6 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
}
.tile.unavailable {
    box-shadow: 0 3px 10px 1px rgb(0 0 0 / 17%) !important;
    border-radius: 8px;
    width: 370px;
    height: 320px;
    position: relative;
    background: #ffffff;
    /* "to left" / "to right" - affects initial color */
    background: linear-gradient(to left, rgb(255, 255, 255) 50%, #ffffff 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
}
.tile:hover.unavailable .description {
    color: #20292f;
}
.tile:hover {
    background-position: left;
}

.label-container {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding: 0px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    width: 100%;
    height: 75px;
    
}
  
.label {
    font-Weight: bold;
    padding-Right: 10px;
    padding-left: 10px;
    background-Color: #f0f0f0;
    border-Radius: 5px;
    padding-Top: 5px; 
    padding-Bottom: 5px;
}

.description {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 21px;
}
.tile:hover .description{
    color:#ffffff;
}