.menu-colaps{
    border-radius: 50%;
    padding: 0px;
    font-size: 21px;
    width: 40px;
    height: 40px;
    margin-left: 5px;
}

.menu-colaps:hover{
    background-color: #ffffff15;
}

.custome-brand{
    padding: 5px 12px;
    width: 240px;
    background-color: var(--mdb-white);
    transition: all 0.3s ease-in-out;
}
.custome-brand-mini{
    width: 80px;
    padding: 3px 0px;
}
.custome-brand-mini a.navbar-brand {
    width: 100% !important;
    justify-content: center !important;
    height: 44px;
}
.custome-brand img{
    transition: all 0.3s ease-in-out;
    width: 75% !important;
    top: 24px !important;

}
.custome-brand-mini img{
    transition: all 0.3s ease-in-out;
    padding: 0px;
    padding-left: 17px;
}

.navbar {
    box-shadow: none !important;
    height: 50px !important;
}

a.navbar-brand:after{
    display: none;
}
a.navbar-brand {
    width: auto !important;
    justify-content: center !important;
    height: 44px;
}
.bg-primary {
    background-color: #198AC4 !important;
}
.rotate-0{
    
    transform: rotate(0deg);
    transition: all 0.5s linear;
}
.rotate-180{
    transform: rotate(180deg);
    transition: all 0.5s linear;
}
.btn .fas {
    margin-right: 0 !important;
}
@media (max-width: 992px) {
    .menu-colaps{
        display: none;
    }

    .custome-brand{
        margin-left: -42px;
        width: 165px;
        padding-left: 54px;
    }
    .navbar-brand {
        height: 44px;
    }

    .navbar-toggler{
        border: 0;
        z-index: 9;
    }
    
    .hide{
        z-index: 0;
    }

    .nav-title{
        display: none;
    }

}
