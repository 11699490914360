.fieldLabel{
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0.5rem;
    display: block;
    float: left;
    width: 50%;
}

.field-row{
    /* margin-bottom: 1rem; */
    border-bottom: 1px dotted #ccc;
    min-height: 1rem;
    padding: 0.5rem;
}
.field-row .form-outline {
    position: relative;
    width: 45%;
    float: left;
}
.form-container.no-shadow {
    padding: 3.5rem !important;
}
.no-shadow{
    box-shadow: none !important;
}

.form-check {
    display: inline-flex !important;
}