.row.abt-box {
    box-shadow: 0 0 5px #ddd;
    border-radius: 5px;
    overflow: hidden;
}
.pt-8{
    padding-top: 7.6em;
}
.abt-right-box h3{
 margin-bottom: 20px;
}
.info-box {
    padding: 25px;
    box-shadow: 0 0 5px #ddd;
    border-radius: 7px;
}
ul.info-list{
    margin-top: 15px;
}
ul.info-list li{
  font-size: 16px;
  line-height: 28px;
}
.form-wraper{
    width: 100%;
    box-shadow: 0px 9px 41px -10px rgba(0, 0, 0, 0.18);
    padding: 20px;
    border-radius: 5px;
}