.text-lg{
    /* font-size: 1.125rem; */
    /* padding-top: 4px; */
    font-weight: bold;
}
.sidebar {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%) !important;
}
.btn{
    padding: 5px 10px;
}
.card {
    box-shadow: 0 3px 10px 1px rgb(0 0 0 / 17%) !important;
}
.card,
.c-width{
    max-width: calc(100% - 0px);
}
.card.w-100{
    max-width: 100%;
    min-width: 350px;
    min-height: 425px;
}
.border-primery {
    border: 1px solid #198ac4;
}
.inner-box {
    padding: 20px;
}
.map-img{
    width: 100%;
    height: 80vh;
    object-fit: cover;
    border-radius: 8px;
}
.mr-1{
    margin-right: 0.50rem;
}
.ml-1{
    margin-left: 0.50rem;
}

.highcharts-container svg > rect{
    fill: #ffffff00 !important;
}

.grid-scroll{
    max-height: 50vh;
    overflow-y: auto;
}
.tab-let{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    /* cursor: pointer; */
    margin-top: -18px;
    margin-left: -19px;
    width: calc(100% + 38px);
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 20%) !important;
}

.tablet-tab{
    /* button type styles */
    border: 1px solid rgb(0 0 0 / 35%);
    border-radius: 3px;
    transition: all 0.3s;
}
.border-one {
    border: 1px solid #c8ced6;
}
.border-one:hover {
    background: #ffedba;
}

.tablet-tab:hover{
    /* glow effect */
    box-shadow: 0px 0px 5px 0px var(--mdb-primary) !important;
    /* border-radius: 5px; */
    /* color: var(--mdb-primary); */
    border-color: var(--mdb-blu) !important;
    cursor: pointer;
}

.tablet-tab.active{
    /* glow effect */
    box-shadow: 0px 0px 5px 0px var(--mdb-primary) !important;
    /* border-radius: 5px; */
    color: var(--mdb-primary);
    border-color: var(--mdb-primary) !important;
    font-weight: bold;
}

.tablet-tab-ghg{
    border-color: #8eb54b !important;
    background-color: var(--ghg-regular) !important;
}
.tablet-tab-ghg:hover {
    box-shadow: 0px 0px 3px 0px #b0b0b0 !important;
    color: #7b9c40;
}

.tablet-tab-ghg.active{
    box-shadow: none !important;
    background-color: #8eb54b !important;
    color: #ffffff;;
    border-color: #8eb54b !important;
}

.tablet-tab-tri {
    border-color: #f05424 !important;
    background-color: var(--tri-regular) !important;
}
.tablet-tab-tri:hover{
    box-shadow: 0px 0px 3px 0px #b0b0b0 !important;
    color: var(--tri);
}

.tablet-tab-tri.active {
    box-shadow: none !important;
    background-color: #f67149 !important;
    color: #ffffff;;
    border-color: #f67149 !important;
}

.tablet-tab-chem{
    border-color: #da3cec !important;
    background-color: #fbf2fc !important;
}
.tablet-tab-chem:hover{
    box-shadow: 0px 0px 3px 0px #b0b0b0 !important;
    color: #da3cec;
}
.tablet-tab-chem.active{
    box-shadow: none !important;
    background-color: #da3cec !important;
    color: #ffffff;;
    border-color: #da3cec !important;
}

.tablet-tab-svi{
    border-color: #198ac4 !important;
    background-color: #f5faff !important;
}
.tablet-tab-svi:hover{
    box-shadow: 0px 0px 3px 0px #b0b0b0 !important;
    color: #198ac4;
}
.tablet-tab-svi.active{
    box-shadow: none !important;
    background-color: #198ac4 !important;
    color: #ffffff;;
    border-color: #198ac4 !important;
}

.tablet-tab-svm{
    border-color: #c26500 !important;
    background-color: #fff7ed;
}
.tablet-tab-svm:hover{
    box-shadow: 0px 0px 3px 0px #b0b0b0 !important;
    color: #c26500;
}
.tablet-tab-svm.active{
    box-shadow: none !important;
    background-color: #c26500 !important;
    color: #ffffff;
    border-color: #c26500 !important;
}

.tablet-tab-hvm{
    border-color: var(--hvm) !important;
    background-color: var(--hvm-regular) !important;
}
.tablet-tab-hvm:hover{
    box-shadow: 0px 0px 3px 0px #b0b0b0 !important;
    color: var(--hvm);
}
.tablet-tab-hvm.active{
    box-shadow: none !important;
    background-color: var(--hvm) !important;
    color: #ffffff;;
    border-color: var(--hvm) !important;
}

.tablet-tab-ebm{
    border-color: #ffc152 !important;
    background-color: #fffbee !important;
}
.tablet-tab-ebm:hover{
    box-shadow: 0px 0px 3px 0px #b0b0b0 !important;
    color: #ffc152;
}
.tablet-tab-ebm.active{
    box-shadow: none !important;
    background-color: #ffc152 !important;
    color: #ffffff;
    border-color: #ffc152 !important;
}

.tablet-tab-cejst{
    border-color: var(--cejst) !important;
    background-color: var(--cejst-regular) !important;
}
.tablet-tab-cejst:hover{
    box-shadow: 0px 0px 3px 0px #b0b0b0 !important;
    color: var(--cejst);
}
.tablet-tab-cejst.active{
    box-shadow: none !important;
    background-color: var(--cejst) !important;
    color: #ffffff;
    border-color: var(--cejst) !important;
}

.tab-let label{
    height:34px;
}
.tab-let label .material-symbols-outlined{
    position: relative;
    font-size: 18px;
    top: -1px;
}
.tab-let label span.tab-label{
    top: -3px;
    position: relative;
    /* margin-left: 2px; */
    font-size: 13px;
    text-overflow: ellipsis;
    width: 100%;
    height: 23px;
    display: block;
    /* left: 15px; */
    overflow: hidden;
    text-align: center;
    /* line-height: 1; */
    /* vertical-align: middle; */

}

.ant-table-wrapper .ant-table .ant-table-header,
.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child,
.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-radius: 8 !important;
}
.ant-table-cell{
    overflow-wrap: normal !important;
}
.fix-width2 {
    position: sticky;
    z-index: 9;
    top: -20px;
}
.bg-info-loc {
    background: rgb(25, 138, 196);
    background: linear-gradient(160deg, rgba(25, 138, 196, 1) 0%, rgba(94, 194, 245, 1) 100%);
}
@media screen and (max-width: 768px) { 
    .mr-1{
        margin-right:0px;
        margin-bottom: 1rem !important;
    }
 }

 table.loc-table td:nth-child(1) {
    background: #189bd6;
}
table.loc-table td:nth-child(2) {
    background: #189bd6;
}
table.loc-table td:nth-child(3) {
    background: #189bd6;
}
table.loc-table td:nth-child(4) {
    background: #189bd6;
}

table.loc-table td:hover {
    background: #68899c;
}
.exportTour {
    position: absolute;
    width: 28px;
    height: 30px;
    background: #ff0b0b;
    top: 41%;
    left: 23px;
    z-index: 9999;
}
input:focus {
    outline: none;
}
.css-t3ipsp-control:hover {
    border-color: hsl(200deg 4.39% 40.2%) !important;
}
.css-t3ipsp-control{
    box-shadow: 0px 0px 5px #ddd !important;
    border-color: hsl(200deg 4.39% 40.2%) !important;
}
.css-1jqq78o-placeholder {
    color: hsl(200deg 3.4% 32.97%) !important;
}
.css-1u9des2-indicatorSeparator {
    background-color: hwb(200 38% 58%) !important;
}
.css-1xc3v61-indicatorContainer {
    color: hsl(200deg 4.39% 40.2%) !important;
}
.css-13cymwt-control {
    border-color: hsl(200deg 4.39% 40.2%) !important;
}
td.ant-table-cell {
    text-transform: uppercase;
}

.pdf-form .form-notch-middle {
    display: none !important;
}
.pdf-form .fieldLabel {
    width: 100% !important;
}
.pdf-form  select {
    display: inline-block !important;
    position: relative !important;
    top: -8px !important;
}

.zip-clr-btn{
    width: 29px !important;
    padding: 4px !important
}

.leaflet-container img {
    filter: grayscale(100%);
  }