.map-container{
    width: 100%;
    height: 80vh;
    border-radius: 15px;
    border: solid 1px var(--mdb-gray-600);
    z-index: 0;
}

.leaflet-container img {
    filter: grayscale(100%);
    opacity: 0.6 !important;
}

.leaflet-container.hide-credits .leaflet-control-attribution{
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
}

.leaflet-bottom.leaflet-right{
    opacity: 0.7;
}

.leaflet-marker-icon.custom-map-pointer.back-layer{
    margin-top: 1px;
}

.map-home-button,
.map-legend-button{
    position: absolute;
    top: 87px;
    left: 10px;
    z-index: 1000;
    background-color: white;
    border: 2px solid rgb(102 102 102 / 40%);
    padding: 4px;
    border-radius: 3px;
    cursor: pointer;
    outline-style: none;
}
.map-home-button{
    top: 87px;
}
.map-legend-button{
    top: 130px;
}
.map-legend{
    background-color: white;
    border: solid 1px var(--mdb-gray-600);
    padding: 4px;
    border-radius: 10px;
    height: 80vh;
}

.btn-legend-close{
    width: 25px;
    height: 25px;
    padding: 2px !important;
}

.map-legend.show{
    display: block;
}

.map-legend.hide{
    display: none;
}

.map-type-dropdown{
    position: absolute;
    top: -2px;
    left: 66px;
    z-index: 1000;
    background-color: #79797950;
    border: 1px solid #69696950;
    padding: 7px 4px;
    border-radius: 3px;
    cursor: pointer;
    width: 185px;
    font-size: 10px;
    opacity: 0.8;
    display: block;
}

.map-type-dropdown:hover{
    opacity: 1;
}

.map-type-dropdown label{
    font-size: 12px;
    font-weight: bold;
}

.map-type-dropdown select{
    width: 100px !important;
    padding: 2px !important;
    font-size: 12px !important;
    background-color: transparent;
    display: inline-block !important;
    top: 4px;
    left: 74px;
    border: solid 1px #292929;
    font-weight: bold;
}

.map-north-arrow{
    position: absolute;
    top: 10px;
    right: 36px;
    padding: 4px;
    cursor: pointer;
    outline-style: none;
    width: 49px;
    height: 70px;
    background-position: center;
    background-size: cover;
    opacity: 1;
    zoom: 0.8;
    z-index: 1000;
}

.map-timeline{
    position: absolute;
    bottom: 0;
    right: 10px;
    left: calc(50% - 250px);
    padding: 10px;
    background-color: #3b3b3b59;
    min-width: 500px;
    width: 500px;
    opacity: 1;
    z-index: 1000;
    font-weight: bold;
    border: 1px solid #69696950;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}

.map-timeline h6{
    font-size: 24px;
}

.map-slider-handle::before{
    width: 3px;
    height: 20px;
    background: #dfdede;
    cursor: pointer;
    border-radius: 5px;
    margin-left: -5px;
}
.map-slider-handle{
    color: #272727;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
}
.map-slider-handle span{
    display: block;
}

.map-slider-handle-block{
    margin-top: -18px;
    width: 102%;
    margin-left: -5px;
}
