:root{
    --mdb-primary-rgb: 48, 125, 193;
    --mdb-warning: #FDC51E;
    --mdb-warning-rgb: 253, 197, 30;
    --mdb-warning2: #FFD056;
    --light-shadow-color: #2929294d;

    /* Tab collors */
    --ghg: #99CC33;
    --ghg-rbg: 53, 204, 51;
    --ghg-regular: #f9ffec;
    --ghg-active-txt:#f9ffec;

    --tri: #FF6600;
    --tri-rbg: 255, 80, 0;
    --tri-regular: #fff4ec;
    --tri-active-txt:#fff4ec;

    /* --chem: #4b4b4b;
    --chem-rbg: 75, 75, 75;
    --chem-regular: #e6e6e6;
    --chem-active-txt:#e7e5e5; */

    --svi: #336699;
    --svi-rbg: 68, 108, 169;
    --svi-regular: #dcebf9;
    --svi-active-txt:#dcebf9;

    --svm: #999999;
    --svm-rbg: 139, 141, 141;
    --svm-regular: #e2e2e2;
    --svm-active-txt:#e2e2e2;

    --hvm: #0099CC;
    --hvm-rbg: 0, 155, 222;
    --hvm-regular: #e8f9ff;
    --hvm-active-txt:#e8f9ff;
    
    --ebm: #FFCC33;
    --ebm-rbg: 255, 197, 48;
    --ebm-regular: #fff7e0;
    --ebm-active-txt:#fff7e0;

    --cejst: #996600;
    --cejst-rbg: 68, 108, 169;
    --cejst-regular: #ede6d7;
    --cejst-active-txt:#ede6d7;
}