.sticky-menu{
    position: fixed;
    top: 168px;
    right: 0;
    background: #ffedba;
    border: solid 1px #ffda90;
    box-shadow: -3px 4px 3px #6161614d;
    border-radius: 5px 0 0 5px;
}
.sticky-menu button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4px;
    margin: 0;
    gap: 5px
}
.sticky-menu ul li svg {
    color: #323232 !important;
}
.sticky-menu button span{
    font-size: 8px;
    color: #323232;
}
.sticky-menu button i{
    color: var(--mdb-gray-700);
}

.sticky-menu ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

.sticky-menu ul li:not(:first-child){
    border-top: solid 2px #ffda90;
}

.sticky-menu ul li img{
    width: 30px;
    opacity: 0.7;
}

.right-nav-container{
    width: 300px;
    position: absolute;
    right: -350px;
    background-color: #ffffff;
    box-shadow: -3px 4px 3px #6161614d;
    transition: all 0.3s ease-in-out;
    top: -118px;
    height: calc(100vh - 50px);
}

.right-nav-container.open{
    right: 0;
}

.right-nav-container .action-btn{
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 15px 0 0 15px;
    box-shadow: -3px 4px 3px #6161614d;
    padding: 5px;
    right: 300px;
    top: 8px;
}
.right-nav-container .action-btn button{
    width: 30px;
    height: 30px;
}

.right-nav-header button{
    width: 50px;
}


@media (max-width: 992px) {
    .sticky-menu{
        display: none;
    }
}







.sticky-container {
    /*background-color: #333;*/
    padding: 0px;
    margin: 0px;
    position: fixed;
    right: -139px;
    top: 130px;
    width: 200px;
    }
    .sticky li {
    list-style-type: none;
    background-color: #333;
    color: #efefef;
    height: 30px;
    padding: 0px;
    padding-left: 2px;
    margin: 0px 0px 1px 0px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    }
    .sticky li:hover {
    margin-left: -42px;
    /*-webkit-transform: translateX(-115px);
            -moz-transform: translateX(-115px);
            -o-transform: translateX(-115px);
            -ms-transform: translateX(-115px);
            transform:translateX(-115px);*/
            background-color: #85b133;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(0%);
    }
    .sticky li svg {
    float: left;
    margin: 5px 5px;
    margin-right: 5px;
    }
    .sticky li p {
    padding: 0px;
    margin: 0px;
    text-transform: uppercase;
    line-height: 28px;
    font-size: 8px;
    }